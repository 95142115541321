// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-angular-developer-js": () => import("./../../../src/pages/angular-developer.js" /* webpackChunkName: "component---src-pages-angular-developer-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-develop-js": () => import("./../../../src/pages/develop.js" /* webpackChunkName: "component---src-pages-develop-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-javascript-developer-js": () => import("./../../../src/pages/javascript-developer.js" /* webpackChunkName: "component---src-pages-javascript-developer-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-python-developer-js": () => import("./../../../src/pages/python-developer.js" /* webpackChunkName: "component---src-pages-python-developer-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

